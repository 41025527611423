import $ from 'jquery';
import Swiper from 'swiper/bundle';

const mainMvSlider = () => {
  const $target = $('.jsMainMvSlider');

  if (!$target.length) {
    return;
  }

  new Swiper($target[0], {
    loop: true,
    speed: 900,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    slidesPerView: 1,
    loopAdditionalSlides: 2,
    navigation: {
      nextEl: $target.find('.swiper-button-next')[0],
      prevEl: $target.find('.swiper-button-prev')[0],
    },
  });
};

export default mainMvSlider;
