import $ from 'jquery';
// eslint-disable-next-line no-unused-vars
import emergence from 'emergence.js';

const telLink = () => {
  const $body = $('body');

  $(() => {
    emergence.init({
      reset: false
    });
  });

  $(window).on('load', () => {
    $body.addClass('is-initialized');
  });
};

export default telLink;
