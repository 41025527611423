import $ from 'jquery';

const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const gdprHtml = `<div class="modGdpr">
  <div class="boxInner">
    <p class="boxText">当ウェブサイトでは、お客様のニーズに合わせたサービスを提供するため、また、当サイトの利用状況を分析するためにCookieを使用します。詳細については<a href="/privacy_policy/">プライバシーポリシー</a>をご参照ください。当ウェブサイトを利用することにより、お客様はCookieの使用に同意することになります。</p>
    <div class="boxButton">
      <a href="javascript:void(0);" class="btnAccept">OK</a>
    </div>
  </div>
</div>`;

const initGdpr = () => {
  const $gdpr = $(gdprHtml);

  if (getCookie('jqa:gdpr') !== '1') {
    $gdpr
      .appendTo('body')
      .find('.btnAccept')
      .on('click', () => {
        $gdpr.stop().fadeOut(200);
        setCookie('jqa:gdpr', '1', 180);
      });
  }
};

export default initGdpr;
