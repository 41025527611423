import $ from 'jquery';
import Screen from './screen';

const headerMenu = () => {
  const screen = new Screen();
  const $header = $('#header');
  const $gnav = $('#gNav');
  const $button = $header.find('.btnMenu');
  const CLASS_OPEN = 'isOpen';

  $button.on('click', function () {
    if ($button.hasClass(CLASS_OPEN)) {
      $(this).removeClass(CLASS_OPEN);
      $gnav.stop().fadeOut(200);
      screen.unlock();
    } else {
      $(this).addClass(CLASS_OPEN);
      $gnav.stop().fadeIn(200);
      screen.lock();
    }
  });

  $(window).on('breakpointChange', () => {
    if (screen.isPC) {
      $button.removeClass(CLASS_OPEN);
      $gnav.removeAttr('style');
      screen.unlock();
    }
  });
};

export default headerMenu;
