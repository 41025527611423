import $ from 'jquery';
import Screen from './screen';

const telLink = () => {
  const $target = $('.jsTelLink');
  const screen = new Screen();

  $target.each(function () {
    // eslint-disable-next-line consistent-return
    $(this).on('click', () => {
      if (screen.isPC) {
        return false;
      }
    });
  });
};

export default telLink;
