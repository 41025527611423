import $ from 'jquery';

const mart = () => {
  const $checkboxs = $('.jsAgree');

  if (!$checkboxs.length) {
    return;
  }

  $checkboxs.each(function () {
    // Get toggle items
    const toggleTarget = $(this).attr('data-target');

    if ($(this).prop('checked')) {
      $(toggleTarget).prop('disabled', false);
    } else {
      $(toggleTarget).prop('disabled', true);
    }

    // change event
    $(this)
      .on('change', (e) => {
        if ($(e.currentTarget).prop('checked')) {
          $(toggleTarget).prop('disabled', false);
        } else {
          $(toggleTarget).prop('disabled', true);
        }
      });
  });
};

export default mart;
