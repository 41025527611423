import $ from 'jquery';

const accordion = () => {
  const $Items = $('.jsAccordion');
  const CLASS_OPEN = 'isOpen';

  if (!$Items.length) {
    return;
  }

  $Items.each(function () {
    // init opened items
    $(this).find('.jsAccordionHead.isOpen').nextAll('.jsAccordionBody').show();

    // click event
    $(this)
      .find('.jsAccordionHead')
      .on('click', (e) => {
        $(e.currentTarget).toggleClass(CLASS_OPEN);
        $(this).find('.jsAccordionBody').stop().slideToggle(200);
      });
  });
};

export default accordion;
