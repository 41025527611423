import 'core-js/modules/es.number.is-nan';
import 'core-js/modules/es.string.repeat';
import $ from 'jquery';
import Screen from './modules/screen';
import headerMenu from './modules/headerMenu';
import footerNavs from './modules/footerNavs';
import btnHeaderSearch from './modules/btnHeaderSearch';
import linkToScroll from './modules/linkToScroll';
import pageTop from './modules/pagetop';
import scrollTo from './modules/scrollTo';
import tabContent from './modules/tabContent';
import tabModal from './modules/tabModal';
import faqAccordion from './modules/faqAccordion';
import initMatchHeight from './modules/matchHeight';
import mainMvSlider from './modules/mainMvSlider';
import initGdpr from './modules/gdpr';
import initDatePicker from './modules/datePicker';
import anchorAccordion from './modules/anchorAccordion';
import telLink from './modules/telLink';
import accordion from './modules/accordion';
import navSticky from './modules/navSticky';
import fileIcon from './modules/fileIcon';
import mart from './modules/mart';
import initImageMap from './modules/imageMap';
import headerSticky from './modules/headerSticky';
import businessNav from './modules/businessNav';
import topMV from './modules/topMV';

/* ----------------------------------------------------------
  init
---------------------------------------------------------- */
$(() => {
  headerMenu();
  footerNavs();
  btnHeaderSearch();
  pageTop();
  scrollTo();
  tabContent();
  tabModal();
  faqAccordion();
  initMatchHeight();
  mainMvSlider();
  initGdpr();
  initDatePicker();
  anchorAccordion();
  telLink();
  accordion();
  navSticky();
  fileIcon();
  mart();
  initImageMap();
  headerSticky();
  businessNav();
});

linkToScroll();
topMV();

/* ----------------------------------------------------------
  Screen
---------------------------------------------------------- */
const screen = new Screen();
$(window).on('resize', screen.size());
