import $ from 'jquery';

export default class Screen {
  constructor() {
    this.ele = document.querySelector('html');
    this.isLocked = 'is-locked';
  }

  // Lock screen
  lock() {
    const top = $(window).scrollTop();

    if (!$(this.ele).hasClass(this.isLocked)) {
      $(this.ele)
        .addClass(this.isLocked)
        .css({ top: top * -1 })
        .data('top', top);
    }
  }

  // Unlock screen
  unlock() {
    const top = $(this.ele).data('top') || 0;

    if ($(this.ele).hasClass(this.isLocked)) {
      $(this.ele).removeClass(this.isLocked).css({ top: '' });
      $(window).scrollTop(top);
    }
  }

  // Check device
  get isPC() {
    this.mq = window.matchMedia('screen and (min-width: 813px)');
    return this.mq.matches;
  }

  // Check device
  get isSP() {
    this.mq = window.matchMedia('screen and (max-width: 812px)');
    return this.mq.matches;
  }

  // Check screen size
  size() {
    this.mq = window.matchMedia('screen and (min-width: 813px)');
    $(this.mq).on('change', () => {
      $(window).trigger('breakpointChange');
    });
  }
}
