import $ from 'jquery';
// eslint-disable-next-line no-unused-vars
import matchHeight from 'jquery-match-height';

const initMatchHeight = () => {
  const $target = $('.jsMhContainer');

  if (!$target.length) {
    return;
  }

  $target.each(function () {
    $(this).find('.jsMhItem').matchHeight({
      property: 'min-height',
    });
  });
};

export default initMatchHeight;
