import $ from 'jquery';
import Screen from './screen';

const navSticky = () => {
  const screen = new Screen();
  const $nav = $('.jsNavStickey');
  const CLASS_OPEN = 'isOpen';

  if (!$nav.length) {
    return;
  }

  $nav.each(function () {
    // click event
    $(this)
      .find('.btnLink')
      .on('click', function (e) {
        e.preventDefault();
        if ($(this).hasClass(CLASS_OPEN)) {
          $(this).removeClass(CLASS_OPEN);
          $(this).next('.subNav').stop().fadeOut(200);
        } else {
          $(this).addClass(CLASS_OPEN);
          $(this).next('.subNav').stop().fadeIn(200);
        }
      });
  });

  $(document).on('click', (e) => {
    if(!$(e.target).closest('.jsNavStickey').length) {
      $nav.find('.btnLink').removeClass(CLASS_OPEN);
      $nav.find('.btnLink').next('.subNav').fadeOut(200);
    }
  });

  $(window).on('breakpointChange', () => {
    if (screen.isSP) {
      $nav.find('.btnLink').removeClass(CLASS_OPEN);
      $nav.find('.btnLink').next('.subNav').removeAttr('style');
    }
  });
};

export default navSticky;
