import $ from 'jquery';
import Screen from './screen';

const CLASS_ACTIVE = 'isActive';
const screen = new Screen();
let $target;
let $btnTab;
let $tabBody;
let $tabContent;

const initTabPc = () => {
  $btnTab.on('click', function (e) {
    e.preventDefault();
    if (!screen.isPC) {
      return;
    }

    const idx = $(this).parent().index();

    if (!$(this).hasClass(CLASS_ACTIVE)) {
      $btnTab.removeClass(CLASS_ACTIVE);
      $tabContent.hide();
      $(this).addClass(CLASS_ACTIVE);
      $tabContent.eq(idx).show();
    }
  });
};

const closeModal = () => {
  $tabBody.stop().fadeOut(200);
  screen.unlock();
};

const initModalSp = () => {
  $btnTab.on('click', function (e) {
    e.preventDefault();
    if (screen.isPC) {
      return;
    }

    const idx = $(this).parent().index();

    screen.lock();
    $btnTab.removeClass(CLASS_ACTIVE);
    $(this).addClass(CLASS_ACTIVE);
    $tabContent.hide();
    $tabContent.eq(idx).show();
    $tabBody.stop().fadeIn(200);
  });

  $tabBody.find('.jsBtnClose').on('click', closeModal);
};

const checkActiveContent = () => {
  $tabContent.hide();

  if (screen.isPC) {
    $tabBody.removeAttr('style');

    if ($btnTab.filter(`.${CLASS_ACTIVE}`).length) {
      const idx = $btnTab.filter(`.${CLASS_ACTIVE}`).parent().index();
      $tabContent.eq(idx).show();
    } else {
      $btnTab.eq(0).trigger('click');
    }
  } else {
    $tabBody.removeAttr('style');
    $tabContent.removeAttr('style');
    screen.unlock();
  }
};

const jsTabModal = () => {
  $target = $('.jsTabModal');

  if (!$target.length) {
    return;
  }

  $btnTab = $target.find('.btnTab');
  $tabBody = $target.find('.tabBody');
  $tabContent = $tabBody.find('.tabContent');

  initTabPc();
  initModalSp();
  checkActiveContent();

  if ($btnTab.filter('.default').length) {
    $btnTab.filter('.default').trigger('click');
  }

  $(window).on('breakpointChange', () => {
    checkActiveContent();
  });
};

export default jsTabModal;
