import $ from 'jquery';

const tabContent = () => {
  const $target = $('.jsTabContent');
  const CLASS_ACTIVE = 'isActive';

  if (!$target.length) {
    return;
  }

  $target.each(function () {
    const $btnTab = $(this).find('.btnTab');
    const $tabContent = $(this).find('.tabContent');

    // tab button
    $btnTab.on('click', function (e) {
      e.preventDefault();
      const idx = $(this).parent().index();

      if (!$(this).hasClass(CLASS_ACTIVE)) {
        $btnTab.removeClass(CLASS_ACTIVE);
        $tabContent.hide();
        $(this).addClass(CLASS_ACTIVE);
        $tabContent.eq(idx).show();
      }
    });

    // init active content
    $tabContent.hide();
    if ($btnTab.filter(`.${CLASS_ACTIVE}`).length) {
      const curId = $btnTab.filter(`.${CLASS_ACTIVE}`).parent().index();
      $tabContent.eq(curId).show();
    } else {
      $btnTab.eq(0).trigger('click');
    }
  });
};

export default tabContent;
