import $ from 'jquery';

const fileIcon = () => {
  let addIconPath = `/common/img/`;
  const $container = $('#contents, .navStickySide');
  const pdfLink = 'a[href$=".pdf"]';
  const wordLink = 'a[href$=".doc"], a[href$=".docx"]';
  const xlsLink = 'a[href$=".xls"], a[href$=".xlsx"], a[href$=".xlsm"]';
  const blankLink = 'a[target="_blank"]';
  const notSelector = ':has(img), .linkImg';
  const pdfIcon = 'ico_pdf.svg';
  const wordIcon = 'icon_word.png';
  const xlsIcon = 'icon_xls.png';
  const blankIcon = 'ico_link_blank_01.svg';
  const blankIconBlack = 'ico_link_blank_02.svg';
  const blankIconWhite = 'ico_link_blank_03.svg';
  const scripts = document.getElementsByTagName('script');
  let root;

  Array.prototype.forEach.call(scripts, (script) => {
    const match = script.src.match(/(.*)\/common\/js\/main\.bundle\.js$/);
    if (match) {
      root = String(match[1]);
    }
  });

  addIconPath = root + addIconPath;

  $container.find(pdfLink).not(notSelector).each(function () {
    if ($(this).hasClass('btnCmnArrow01') || $(this).hasClass('btnCmnArrow02') || $(this).parents('.newsList1').length) {
      $(this).addClass('hasIcon');
    }
    $(this).append(`<img src="${  addIconPath  }${pdfIcon  }" class="iconImg iconPdf">`);
  });

  $container.find(wordLink).not(notSelector).each(function () {
    if ($(this).hasClass('btnCmnArrow01') || $(this).hasClass('btnCmnArrow02') || $(this).parents('.newsList1').length) {
      $(this).addClass('hasIcon');
    }
    $(this).append(`<img src="${  addIconPath  }${wordIcon  }" class="iconImg iconWord">`);
  });

  $container.find(xlsLink).not(notSelector).each(function () {
    if ($(this).hasClass('btnCmnArrow01') || $(this).hasClass('btnCmnArrow02') || $(this).parents('.newsList1').length) {
      $(this).addClass('hasIcon');
    }
    $(this).append(`<img src="${  addIconPath  }${xlsIcon  }" class="iconImg iconXls">`);
  });

  $container.find(blankLink).not(`${pdfLink  }, ${  wordLink  }, ${  xlsIcon  }, ${  notSelector}`).each(function () {
    if ($(this).hasClass('btnCmnArrow01') || $(this).hasClass('btnCmnArrow02') || $(this).parents('.newsList1').length) {
      $(this).addClass('hasIcon');
    }

    if ($(this).hasClass('btnCmnArrow01') || $(this).hasClass('btnCmnArrow02')) {
      $(this).append(`<img src="${  addIconPath  }${blankIconWhite  }" class="iconImg iconWin">`);
    } else if ($(this).parents('.newsList1').length) {
      $(this).append(`<img src="${  addIconPath  }${blankIconBlack  }" class="iconImg iconWin">`);
    } else {
      $(this).append(`<img src="${  addIconPath  }${blankIcon  }" class="iconImg iconWin">`);
    }

    if ($(this).hasClass('btnCmnArrow01')) {
      $(this).on({
        'mouseenter': function() {
          const $currentIcon = $(this).find('img.iconWin');
          $currentIcon.attr('src', $currentIcon.attr('src').replace('03', '04'));
        },
        'mouseleave': function() {
          const $currentIcon = $(this).find('img.iconWin');
          $currentIcon.attr('src', $currentIcon.attr('src').replace('04', '03'));
        }
      })
    }
  });
};

export default fileIcon;
