import $ from 'jquery';

const faqAccordion = () => {
  const $list = $('.jsFaqAccordion');
  const CLASS_OPEN = 'isOpen';

  if (!$list.length) {
    return;
  }

  $list.each(function () {
    // init opened items
    $(this).find('.question.isOpen').next().show();

    // click event
    $(this)
      .find('.question')
      .on('click', function () {
        $(this).stop().toggleClass(CLASS_OPEN);
        $(this).next().stop().slideToggle(200);
      });
  });
};

export default faqAccordion;
