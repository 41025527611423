import $ from 'jquery';

const linkToScroll = () => {
  const { hash } = window.location;

  if (!hash && !$(hash).length) {
    return;
  }

  window.history.replaceState(null, null, ' ');

  if (hash === '#tab02' && $('.jsTabModal').length) {
    $('.jsTabModal').find('.btnTab').eq(1).addClass('default');
  }

  $(window).on('load', () => {
    if ($(hash).length) {
      const headerHeight = $('#header').height();
      const targetOffset = $(hash).offset().top - headerHeight;
      $('html, body').animate({ scrollTop: targetOffset }, 0);
    }
  });
};

export default linkToScroll;
