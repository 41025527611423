import $ from 'jquery';

const pageTop = () => {
  const $pageTop = $('#pageTop');
  const CLASS_HOVER = 'isHover';

  $(window).on('scroll', () => {
    const scrollTop = $(window).scrollTop();

    if (scrollTop > 0) {
      $pageTop
        .not(':animated')
        .stop()
        .fadeIn(200, function () {
          $(this).addClass(CLASS_HOVER);
        });
    } else {
      $(this).removeClass(CLASS_HOVER);
      $pageTop.not(':animated').stop().fadeOut(200, function () {
        $(this).removeClass(CLASS_HOVER);
      });
    }
  });

  $pageTop.on('click', (e) => {
    e.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, 400);
  });
};

export default pageTop;
