import $ from 'jquery';
import Screen from './screen';

const footerNavs = () => {
  const screen = new Screen();
  const $footer = $('#footer');
  const $navs = $footer.find('.navList').find('.btnMore');
  const CLASS_OPEN = 'isOpen';

  $navs.on('click', function (e) {
    e.preventDefault();
    const $boxSub = $(this).closest('.boxMain').next('.boxSub');

    if ($boxSub.is(':hidden')) {
      $(this).parent().addClass(CLASS_OPEN);
      $boxSub.stop().slideDown(200);
    } else {
      $(this).parent().removeClass(CLASS_OPEN);
      $boxSub.stop().slideUp(200);
    }

    return false;
  });

  $(window).on('breakpointChange', () => {
    if (screen.isPC) {
      $navs.parent().removeClass(CLASS_OPEN);
      $footer.find('.navList').find('.boxSub').removeAttr('style');
    }
  });
};

export default footerNavs;
