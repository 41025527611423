import $ from 'jquery';
import Screen from './screen';

const anchorAccordion = () => {
  const $target = $('.jsAnchorAccordion');
  const CLASS_OPEN = 'isOpen';
  const screen = new Screen();

  if (!$target.length) {
    return;
  }

  $target.each(function () {
    const $button = $(this).find('.boxSub').prev();

    // eslint-disable-next-line consistent-return
    $button.on('click', function () {
      if (screen.isPC) {
        return false;
      }

      if ($(this).hasClass(CLASS_OPEN)) {
        $(this).removeClass(CLASS_OPEN).next().stop().slideUp(200);
      } else {
        $(this).addClass(CLASS_OPEN).next().stop().slideDown(200);
      }
    });

    $button
      .parent()
      .on('mouseenter', function () {
        if (!screen.isPC) {
          return;
        }

        $(this)
          .children('.linkAnchor')
          .addClass(CLASS_OPEN)
          .next()
          .stop()
          .slideDown(200);
      })
      .on('mouseleave', function () {
        if (!screen.isPC) {
          return;
        }

        $(this)
          .find('.linkAnchor')
          .removeClass(CLASS_OPEN)
          .next()
          .stop()
          .slideUp(200);
      });
  });
};

export default anchorAccordion;
