import $ from 'jquery';

const scrollTo = () => {
  const $btn = $('.jsScroll');

  if (!$btn.length) {
    return;
  }

  $btn.on('click', function (e) {
    e.preventDefault();
    const hash = $(this).attr('href');

    if (hash && !$(hash).length) {
      return;
    }

    const header = $('#header').height();
    const offset = $(hash).offset().top - header;

    $('html, body').animate({ scrollTop: offset }, 400);
  });
};

export default scrollTo;
