import $ from 'jquery';

const businessNav = () => {
  const $target = $('.jsBusinessNav');
  const CLASS_ACTIVE = 'isActive';

  if (!$target.length) {
    return;
  }

  $target.each(function () {
    const $btnNav = $(this).find('.btnNav');
    const $panelContent = $(this).find('.panelTopBusiness');

    // tab nav
    $btnNav
      .on('mouseenter', function () {
        const idx = $(this).parent().index();

        if (!$(this).hasClass(CLASS_ACTIVE)) {
          $btnNav.removeClass(CLASS_ACTIVE);
          $panelContent.removeClass(CLASS_ACTIVE);
          $(this).addClass(CLASS_ACTIVE);
          $panelContent.eq(idx).addClass(CLASS_ACTIVE);
        }
      });

    $panelContent.removeClass(CLASS_ACTIVE);

    if ($btnNav.filter(`.${CLASS_ACTIVE}`).length) {
      const curId = $btnNav.filter(`.${CLASS_ACTIVE}`).parent().index();
      $panelContent.eq(curId).addClass(CLASS_ACTIVE);
    } else {
      $btnNav.eq(0).trigger('mouseenter');
    }
  });
};

export default businessNav;
