import $ from 'jquery';

const headerSticky = () => {
  const $header = $('#header');
  const CLASS_FIXED = 'isFixed';
  const CLASS_SCROLL_ONCE = 'isScrollOnce';

  $(window).on('scroll', () => {
    const scrollTop = $(window).scrollTop();

    if (scrollTop > 50 || $('html').hasClass('is-locked')) {
      $header.addClass(CLASS_FIXED);
      if (!$header.hasClass(CLASS_SCROLL_ONCE)) {
        $header.addClass(CLASS_SCROLL_ONCE);
      }
    } else {
      $header.removeClass(CLASS_FIXED);
    }
  }).trigger('scroll');

};

export default headerSticky;
