import $ from 'jquery';
import Screen from './screen';

const btnHeaderSearch = () => {
  const screen = new Screen();
  const $headerSearch = $('#headerSearch');
  const $button = $('.jsBtnHeaderSearch');
  const CLASS_OPEN = 'isOpen';

  $button.on('click', function (e) {
    e.preventDefault();
    if ($button.hasClass(CLASS_OPEN)) {
      $(this).removeClass(CLASS_OPEN);
      $headerSearch.stop().fadeOut(200);
      screen.unlock();
    } else {
      $(this).addClass(CLASS_OPEN);
      $headerSearch.stop().fadeIn(200);
    }
  });

  $(window).on('breakpointChange', () => {
    if (screen.isSP) {
      $button.removeClass(CLASS_OPEN);
      $headerSearch.removeAttr('style');
    }
  });
};
export default btnHeaderSearch;
